.generate-invoice {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.generate-invoice__buttons {
  width: 100%;
  border-top: 2px solid black;
}

.generate-invoice__body {
  overflow-x: hidden;
}

.generate-invoice__button {
  height: 40px;
}

.title {  
  font-weight: bold;
  font-size: 2rem;
  color: var(--adm-color-success);
  font-family: var(--font-family-bold);
  text-align: start;
  padding-left: 1rem;
  
}

.title > span {
  color: #000;
  font-size: 1.5rem;
}

.presentation-card {
  background-color: #fff;
  width: 80%;
  padding: 2rem 1rem;
  border-radius: 10px;
}

.claim__organization,
.claim,
.amount,
.claim-footer,
.amount-footer {
  font-family: var(--font-family-bold);
}


.detail, .detail-footer {
  display: flex;
  justify-content: flex-end;  
}

.claim-footer, .amount-footer {
  padding: 5px 0;
}

.amount-footer {
  padding-left: 2rem;

}

.generate_invoice__button { 
  display: flex;
  align-items: center;
  justify-content: center;
}
.generate_invoice__button > label {
  cursor: pointer;
}

.inputFile {
  display: none;
}