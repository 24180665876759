.summary-container .adm-search-bar-input-box-icon svg{
    color: white;
}
.summary-container .adm-search-bar {
    --background: #988e75;
    --border-radius: 20px;
}

.summary-container .adm-input-wrapper {
    background: #988e75;
    border-radius: 20px;
}

.summary-container .barra {
    margin: 0 8px; /* Espacio a los lados de la barra */
    width: 1px; /* Grosor de la barra */
    height: 16px; /* Altura de la barra */
    background-color: #5e5e5e; /* Color de la barra */
}
.summary-container .tabla-datos {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
}

.summary-container th {
  text-transform: uppercase;
  font-weight: bold;
  color: #5e5e5e;
  padding-bottom: 10px;
  text-align: end;
}

.summary-container td {
  padding: 10px 5px;
  color: #777676;
  border-bottom: 1px solid #d9e4d3;
}

.summary-container td:nth-child(2), /* Columna de Órdenes */
.summary-container td:nth-child(3) { /* Columna de Montos */
  text-align: end;
}

.summary-container .nombre {
  color: #2AA726;
  font-weight: bold;
}

.summary-container tbody tr:hover {
  background-color: #f9f9f9;
}
  