.form-title {
  font-size: 28px;
  font-weight: bold;
  color: var(--adm-color-primary);
  margin-bottom: 20px;
  margin-top: 20px;
  text-align: center;
  color: #222627;
}


.label-color {
  color: red;
}
.form-button__medicalRecord {
  width: 100%;
  padding: 10px;
  background-color: var(--adm-color-primary);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.form-button__medicalRecord:hover {
  background-color: var(--adm-color-primary-dark);
}

  

li { 
  margin: 0; 
  background: var(--adm-border-color); 
}

.bg-form {
  background-color: #e8e8e8;
}

.form-group {
  background: white;
  padding: 10px;
  width: 100%;
  margin-bottom: 20px;
  border-radius: 10px;
}

.form-field__input {
  border: none;
  border-bottom: 1px solid rgba(36, 36, 36, 0.927);
  border-radius: 0;
}

/* li:nth-child(odd) {
  background-color: var(--adm-color-light);
} */


.css-119haig-MuiGrid-root {
  padding: 0 20px;
}
.css-msgan7-MuiGrid-root {
  background-color: aqua;
}
