.billing {
}

.billing__container {
    padding: 0 20px;
}

.header_data{
    display: flex;
    justify-content: space-between;
    padding: 2rem;
}

.header__body--primary{
    text-transform: uppercase;
    color: var(--adm-color-success);
    font-size: 1.25rem;
    font-weight: 900;
}

.header__body--secondary{
    color: var(--adm-color-weak);
    font-size: 1rem;
}