.adm-card {
    background-color: transparent;
    padding: 0;
}

.adm-card-body{
    padding: 0;
}

.patient-manager-container{
   align-content: center;
}
.name-title {
    margin-top: 20px;
    font-size: 1rem;
    text-transform: uppercase;
    color: var(--adm-color-primary);
    font-family: var(--font-family-bold);
    text-align: justify;
}

.organization-subtitle {
    font-size: .75rem;
    text-transform: uppercase;
    color: var(--adm-color-weak);
    font-family: var(--font-family-bold);
    text-align: justify;
}

.editPatient__button {
    margin-top: .5rem;
    font-size: 1.2rem;
    color: var(--adm-color-primary);
    border: 2px solid var(--adm-color-primary);
    border-radius: 50%;
    height: 2rem;
    width: 2rem;
    padding: .25rem;
}

.newPatient__text {
    font-size: .75rem;
    text-transform: uppercase;
    color: var(--adm-color-primary);
    font-family: var(--font-family-bold);
    text-align: justify;
}

.newPatient__subtext {
    font-size: .75rem;
    text-transform: uppercase;
    color: var(--adm-color-weak);
    font-family: var(--font-family-bold);
    text-align: justify;
}

.adm-card-header {
    display: block;
    padding: 0;
    border: 1px solid #f3f3f3;
}

.adm-card {
    box-shadow: none;
    width: 100%;
}

.adm-card-header:not(:last-child) {
    border-bottom: 1px solid #f3f3f3;
}

.adm-dropdown-open .adm-dropdown-nav {
    border-bottom: 1px solid transparent;
}

.adm-space {
    border-bottom: 1px solid #f3f3f3;
}

.adm-space-item .btn-success {
    background: #00a92b;
    width: 100%;
    margin-top: 15px;
}

.adm-space-item {
    width: 100%;
}



.adm-search-bar-input-box-icon{
    height: 100%;
}
.adm-search-bar .adm-search-bar-input-box .adm-search-bar-input-box-icon {
    font-size: 18px;
}

.adm-search-bar .adm-search-bar-input-box .adm-search-bar-input-box-icon {
    padding: 4px 12px;
}

.adm-input-wrapper {
    height: 100%;
    background: #f3f3f3;
    padding: 8px 12px;
}

.adm-search-bar .adm-search-bar-input-box .adm-search-bar-input {

    padding: 8px 12px;
}

.adm-search-bar .adm-search-bar-input-box .adm-search-bar-input {
    height: 100%;
}

.adm-search-bar .adm-search-bar-input-box{
    border: none;
}

.adm-search-bar-input {
    border-radius: 0;
}

.prueba .adm-dropdown {
    border-bottom-right-radius: 30px;
    border-bottom-left-radius: 30px;
    /* box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05); */
    border: 1px solid #b4b4b4;
}

.adm-space {
    width: 100%;
}

.adm-space-vertical{
    text-align: center;
}

/* form {
    text-transform: lowercase;
} */


