.my-button {
    width: 100%;
    background-color: #239B56;
    color: white;
    border-radius: 8px;
    
  }
  
  @media (max-width: 570px) {
    .my-button {
      width: 100%;
    }
  }

  @media (max-width: 570px) {
    .my-list {
      width: 100%;
    }
  }

  .column-content {
    width: '100%';
  }