.separator {
  margin: .3rem;
}

.opt{
    text-transform: uppercase;
    height: 5rem;
    width: 7rem;
    font-size: 0.5rem;
    text-align: center;

    /*ellipsis*/
    transition: transform 0.2s ease-in-out, background-color 0.2s;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .adm-search-bar-input-box {
    border:none
  }




  /* ! REFACTOR PLEASE */
  .no-suggestions {
    color: #999;
    padding: 0.25rem;
  }
  .suggestions {
    text-align: left;
    border: 1px solid #999;
    border-top-width: 0;
    list-style: none;
    margin-top: 0;
    max-height: 120px;
    overflow-y: auto;
    padding-left: 0;
    width: 95vw;
  }
  .suggestions li {
    padding: 0.25rem;
  }
  .suggestion-active,
  .suggestions li:hover {
    background-color: var(--adm-color-success);
    color: #fff;
  }
  .suggestions li:not(:last-of-type) {
    border-bottom: 1px solid var(--adm-color-weak);
  }

  .adm-grid .adm-grid-item {
    margin: 0;
    padding: 0;
    /* height: 0; */
  }

  .adm-button {
    border-radius: 1px;
  }