.left-menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: 80vh;
}

.left-menu__options {
  width: 100%;
}

.left-menu__button {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.ant-drawer-header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 30px;
}


.ant-menu-item a,
.ant-menu-item a:hover,
.ant-menu-item a:focus {
  text-decoration: none;
}