.page-container {
    width: 100%;
    position: relative;
}
.form-container-singup {
    width: 100%;
    position: absolute;
    top: 80%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    padding: 0 20px;
}

.options-container {
    height: 50%;
}

.logo-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: -50px 0;
}

.logo {
    width: 50%;
    margin: 15px;

}



.option-title {
    color: #A79F81;
    font-weight: bold;

}

.buttons-container {
    margin: 2px 2px 2px;

}

.option-buttons {

    padding: 10px;
    width: 150px;
    margin: 2px;

}

.go-link {
    text-decoration: none;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

@media (max-width:768px) {
    .logo {
        width: 75%;
        margin-top: 50px;
    }

    .page-container {
        width: 100%;
        position: relative;
        transform: translateX(-50%);
    }

    .form-container {
        width: 100%;
        position: absolute;
        transform: translateX(50%);
        padding: 0 20px;
    }
}