
.generate-invoice__body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}



.invoice__total {
  font-size: 1.5rem;
  font-weight: bold;
}

.invoice__total > .value {
  color: var(--adm-color-success);
  font-family: var(--font-family-bold);
}
