.chart-container {
    width: 100%;
    max-width: 400px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    margin-top: 15px;
}
  
  .donut-chart {
    width: 100%;
    height: auto;
  }
  
  .chart-info {
    text-align: center;
    color: #5e5e5e;
    width: 140px;
  }
  
  .chart-info-title, .chart-info-value {
    font-size: 18px;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    text-align: left;
    color: #2AA726;
  }

  .chart-info-title span, .chart-info-value span{
    font-size: 14px;
    color: rgb(119, 118, 118);
    font-weight: normal;
  }

.line {
    width: 100%;
    background: #e2eddf;
    margin: 8px 0;
    height: 3px;
}
