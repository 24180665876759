.form__container {
  max-width: calc(95vw - 1rem);
  margin: 0 auto;
  height: 100%;}

.form__button {
  margin-top: 1rem;
  cursor: pointer;
}

.section__token {
  width: 90%;
  margin-top: 20px;
  border-radius: 0;
  border: 0.5px solid #6b7575;
}
