.organization-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid var(--adm-color-success);
  border-left: 8px solid var(--adm-color-success);
  padding: 0 0 0 10px;
  margin-bottom: 10px;
  height: 100px;
  width: 100%;
  background: none;
	color: inherit;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

.organization-card button {
  /* background-color: #F5F5F5; */
  border: none;
  width: 100%;
}

.organization-card .organization-card__check {
  margin-right: 20px;
}

.organization-card .organization-card__field {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: space-around;
  height: 80px;
  width: auto;
}

.organization-card .organization-card__field--name {
  text-align: center;
  width: 100%;
}

.organization-card .organization-card__field--subtotal {
  right: 0;
  font-weight: bold;
}
