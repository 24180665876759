.chart__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.chart__info{
    margin-left: 1rem;
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    padding-bottom: 2rem;

}

.chart__info h3 {
    text-transform: uppercase;
    color: var(--adm-color-success);
    font-size: 1rem;
    font-weight: 900;
    margin-bottom: 0;
    text-align: start;
}

.chart__info--data {
    text-align: center;
    color: var(--adm-color-weak);
    font-size: 1rem;
}

.separator{
    border-right: 3px solid rgba(30,164,55,0.20);
}