/* Estilos para TablaPacientes.css */
.tabla-pacientes {
    width: 100%;
    max-width: 500px;
    border-collapse: collapse;
    margin: 12px auto;
    font-family: Arial, sans-serif;
    padding: 0 20px;
    font-size: 12px;
  }
  
  .tabla-header {
    display: flex;
    /* background-color: #f0f0f0; */
    font-weight: bold;
    text-transform: uppercase;
    color: #1d9543;
    padding: 10px 0;
    font-size: 14px;
  }
  
  .tabla-row {
    display: flex;
    align-items: center;
    padding: 6px 0;
    /* border-bottom: 1px solid #e0e0e0; */
  }
  
  .tabla-row:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .tabla-row:nth-child(odd) {
    background-color: #ffffff;
  }
  
  .tabla-row:last-child {
    border-bottom: none;
  }
  
  .columna {
    flex: 1;
    text-align: left;
    padding: 5px 14px;
  }
  
  .paciente-nombre {
    font-weight: bold;
    position: relative;
  }
  
  .paciente-nombre::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 6px;
    height: 100%;
    background-color: #1d9543;
    margin-right: 10px;
}
  
.align-right {
    text-align: right;
}