/* Original styles */
.adm-floating-panel-content {
  /* background-color: whitesmoke; */
}

.adm-dropdown-item .adm-dropdown-item-title {
  position: relative;
  max-width: 100%;
  color: var(--adm-color-success);
  font-size: 1.5rem;
  padding: 5px;
}

.panel_container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 30px;
  border-radius: 0 0 30px 30px;
}

.panel_container h2 {
  color: var(--adm-color-success);
  font-size: 2rem;
  margin-bottom: 0;
}

.panel_container h3 {
  color: var(--adm-color-success);
  margin: 0;
  font-size: 1rem;
}

.panel-item {
  margin-bottom: 10px;
}

.panel_row {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.panel_row--title {
  color: var(--adm-color-primary);
  font-weight: bold;
  text-align: center;
  font-size: 1.25rem;
}

.panel_row--element {
  display: flex;
  flex-direction: column;
  width: 95%;
  padding: 0 20px;
}

.panel_column {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-top: .5rem;
}

.panel_column--total h3 {
  font-size: small;
  color: var(--adm-color-primary);
  margin-left: 30px;
}

.panel_column--date h3 {
  font-size: small;
  color: var(--adm-color-primary);
  margin-right: 30px;
}

.panel_column--line {
  width: 80%;
  height: 3px;
  margin-left: calc(87vw - 100%);
  background: #dfecdc;
}

.bullet-logo {
  width: 30px;
  height: 30px;
}

.adm-popup-body .adm-dropdown-popup-body .adm-popup-body-position-top,
.adm-dropdown-popup .adm-dropdown-popup-body,
.adm-dropdown-item-content {
  border-radius: 0 0 30px 30px;
}

.disabled-link {
  pointer-events: none;
}

.panel-animate-wrapper .custom-dropdown .adm-dropdown-item-title-arrow {
  display: none;
}

.panel-animate-wrapper .adm-dropdown-item {
  --adm-font-size-main: 1rem;
}

.adm-dropdown-item .adm-dropdown-item-title:after {
  display: none;
}

.adm-dropdown-item-content .dropdown-list {
  margin-top: 15px;
}

.adm-dropdown-item-content .dropdown-item-list {
  display: flex;
  justify-content: space-between;
  background: white;
  padding: 10px 14px;
  position: relative;
  color: rgb(169, 158, 128);
  width: 100%;
}

.adm-dropdown-item-content .dropdown-item-list::before,
.adm-dropdown-item-content .dropdown-item-list::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  height: .1rem;
  background-color: #6b757524;
}

.adm-dropdown-item-content .dropdown-item-list::before {
  top: 0;
}

.adm-dropdown-item-content .dropdown-item-list::after {
  bottom: 0;
}

.adm-dropdown-item-content .dropdown-item-list:not(.last)::after {
  display: none;
}

/* New styles */
.panel {
  /* Add any specific styles for the panel if needed */
}

.prueba {
  /* Add any specific styles for the prueba class if needed */
}

.dropdown-title {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.total-amount {
  line-height: 1;
  margin-top: 15px;
  font-size: 28px;
}

.to-collect {
  font-size: 14px;
}

.dropdown-arrow {
  /* Add any specific styles for the arrow container if needed */
}

.arrow-icon {
  color: white;
  background: #d3cdbc;
  width: 23px;
  height: 23px;
  border-radius: 100%;
  padding: 4px;
}

.panel-color {
  color: #a99e80;
}

.month-title {
  font-weight: bold;
}

.adm-dropdown-item-content .dropdown-item {
  background: white;
  display: flex;
  align-items: center;
}

.adm-dropdown-item-content .dropdown-list {
  padding-left: 0;
}

.logo-image {
  width: 30px;
  height: 30px;
  margin-right: 28px;
}

.date, .amount-panel {
  font-size: 12px;
}

.no-top-padding {
  padding-top: 0px;
}