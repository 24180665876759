.form-title {
  font-size: 25px;
  color: var(--adm-color-primary);
  margin-bottom: 10px;
}

.list-group-flush>.list-group-item {
  border: none
}

.divider {
  margin: 12px;
}