.claim-card {
  border: 1px solid var(--adm-color-success);
  border-left: 8px solid var(--adm-color-success);
  padding: 10px;
  margin-bottom: 10px
}

.claim-card--hidden {
  display: none;
}

.claim-card__date {
  display: flex;
  justify-content: flex-end;
}

.claim-card__date--checkbox {
  position: absolute;
  padding-top: 40px;
  padding-right: 25px;
}

.claim-card__field {
  display: flex;
  justify-content: flex-start;
}

input[type=checkbox]
{
  -ms-transform: scale(1.5); /* IE */
  -moz-transform: scale(1.5); /* FF */
  -webkit-transform: scale(1.5); /* Safari and Chrome */
  -o-transform: scale(1.5); /* Opera */
  transform: scale(1.5);
  margin: 5px 0;
}

.ant-collapse-content-box {
  padding: 0 !important;
}

.checklist {
  padding: .5rem;
  font-size: .85rem;
  text-align: start;

}

.checklist__item {
  padding: 0;
}

.claim__field {
  font-size: .8rem;
}

.claim__field--data {
  font-size: .87rem;
  font-weight: bold;
}

.claim__name,
.claim__amount {
  font-weight: bold;
  font-size: 1rem;
  color: var(--adm-color-success);
  font-family: var(--font-family-bold);
}
