.forgot-password {
  margin: 10px;
  padding: 10px;
  
}

.forgot-password__field {
  background-color: var(--adm-color-form);
  border: none;
  padding: 15px 10px;
  margin-bottom: 20px;
  width: 100%;
}

.login__button {
  margin: 20px 0;
  padding: 10px 20px;
  width: 80%;
  background-color: var(--adm-color-success);
  color: var()
}

.forgot-password__logo{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: -50px 0;
}
