.patient-detail .tabla-pacientes {
    width: 100%;
    max-width: 500px;
    border-collapse: collapse;
    margin: 12px auto;
    font-family: Arial, sans-serif;
    padding: 0 20px;
    font-size: 11px;
  }
  
  .patient-detail .tabla-row {
    display: flex;
    align-items: center;
    padding: 0px;
    margin-bottom: 8px;
    /* border-bottom: 1px solid #e0e0e0; */
  }
  
  .patient-detail .tabla-row:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .patient-detail .tabla-row:nth-child(odd) {
    background-color: #ffffff;
  }
  
  .patient-detail .tabla-row:last-child {
    border-bottom: none;
  }
  
  .patient-detail .columna {
    flex: 1;
    text-align: left;
    padding: 3px 14px;
  }
  
  .patient-detail .paciente-nombre {
    font-weight: bold;
    position: relative;
  }

  
.patient-detail .align-right {
    text-align: right;
}

.patient-detail button {
    width: 105px;
    border: none;
    background: #2AA726;
    color: white;
}

.patient-detail .line {
    height: 2px;
    margin-bottom: 14px;
    margin-top: 8px;
}

.patient-detail .navbar {
  background: #a89d7f;
  height: 60px;
  display: flex;
  width: 100%;
  align-items: center;
  gap: 20px;
  padding: 10px 20px;
}

.patient-detail .search {
  width: 100%;
}

.patient-detail .icon { 
  font-size: 40px;
  color: white;
}

.patient-detail .header-container {
  display: flex;
  justify-content: space-between;
  padding: 20px 20px 15px 20px;
}

.patient-detail .header-container p {
  margin: 0;
  font-weight: bold;
  color: #2AA726;
  font-size: 16px;
}

.patient-detail .header-container .day {
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: flex-end;
}

.patient-detail .header-container .day p {
  margin: 0;
  color: #777676;
  line-height: 1;
}

.patient-detail .name-container {
  padding: 0 20px;
}

.patient-detail .name-container h1 {
  font-weight: bold;
  color: #a89d7f;
  margin-bottom: 0px;
  font-size: 30px;
  letter-spacing: 1px;
}

.patient-detail .organization {
  color: #2AA726;
  font-weight: bold;
  font-size: 16px;
}


