.formik-label {
  font-weight: bold;
  display: flex;
  margin-bottom: 5px;
}

.formik-input+.formik-label {
  font-weight: bold;
  display: inline-flex;
  margin-right: 20px;
}

.formik-input,
.formik-textarea {
  display: block;
  width: 100%;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 6px;
  resize: none;
  box-sizing: border-box;
  /* margin-bottom: 20px; */
}

.formik-input:focus,
.formik-textarea:focus {
  background-color: rgb(224, 247, 255);
}

input[type="radio"] {
  cursor: pointer;
}

.radio-options {
  margin-left: 12px;
}

.form-control {
  margin-bottom: 20px;
}

.error {
  margin-top: 3px;
  color: red;
  text-transform: initial;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.submitButton {
  background-color: #09be6a;
  color: white;
  border-radius: 5px;
  height: 30px;
  width: 100px;
  border: none;
  cursor: pointer;
  margin-left: 10px;
}