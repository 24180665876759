.subscription {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90vh;
}

.subscription .message {
  padding: 0 30px;
}

.subscription .message .button {
  margin: 30px 0 60px;
}
