.bill-detail-os__container {
}

.bill-details-os__table {
    margin-top: 2rem;
    padding: 0 20px;
}

.bill-detail-os__os-type-title {
    display: flex;
    align-items: flex-start;
    font-size: 20px;
    font-weight: bold;
    color: var(--adm-color-primary);
    margin-bottom: 10px;
    padding-left: 7px;
}

.bill-detail-os__patient-item {
    font-size: 0.80em;
    border-left: 5px solid var(--adm-color-success);
    padding: 5px 3px 5px 5px;
    margin: 5px 0 5px 0;
    list-style-type: none;
}
/* .bill-detail-os__patient-item:nth-child(odd) { background-color: whitesmoke; } */

.title-name {
    display: flex;
    justify-content: flex-start;
    padding-left: 7px;
    font-size: 1.5em;
    color: var(--adm-color-success);
    font-weight: bold;
}

.title-amount, .title-claim {
    display: flex;
    justify-content: flex-end;
    font-size: 1.1em;
    font-weight: bold;
    color: var(--adm-color-text);
}

.name {
    font-size: 1em;
    font-weight: bold;
    display: flex;
    justify-content: flex-start;
}

.amount {
    font-size: 1em;
    color: var(--adm-color-weak);
    display: flex;
    justify-content: flex-end;
}

.ant-collapse-item .ant-collapse-no-arrow {
    width: 80%;
}

.bill-detail-os__patient-item .ant-collapse .ant-collapse-item .ant-collapse-header {
    display: contents;
    padding: 0;
    line-height: 1.5715;
    cursor: pointer;
    transition: all 0.3s, visibility 0s;
}