.name {
  color: var(--adm-color-success);
  font-family: var(--font-family-bold);
  text-align: start;
}

.title-name,
.title-claim, .title-amount {
  color: var(--adm-color-weak);
  font-family: var(--font-family-bold);
}


.claim__organization {
  color: var(--adm-color-primary);
  font-family: var(--font-family-bold);
  text-align: start;
}

a {
  color: unset !important ;
}

.billDetailOS__container {
  min-width: 350px;
  width: 100%;
}

.selectAll {
  padding-right: .5rem;
}