.nav {
    /* position: fixed; */
    top: 0;
    z-index: 20;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-transform: uppercase;
    color: var(--adm-color-white);
    background-color: var(--adm-color-weak);
}
.summary-container .nav {
    padding: 0 12px;
}

.menuButton {
    color: white !important;
}

.nav-title {
    margin: 0px;
    font-weight: bold;
    letter-spacing: 1px;
}

.ant-drawer-body {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}