@import-normalize;
/* bring in normalize.css styles */

@font-face {
  font-family: "AG Book";
  src: url("./fonts/AG-Book-Rounded-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AG Book Bold';
  src: url('./fonts/ag-book-rounded-bold.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

/* rest of app styles */
* {
  margin: 0;
}

html, body, #app, #app>div {
  /* height: calc(100vh - 1rem); */
}

html, body {
  height: 0;
}

:root * {
  --adm-color-primary: #ab9f80;
  --adm-color-secondary: #2AA726;
  --adm-color-form: #E6E3D9;
  --adm-color-success: #00a92b;
  --adm-color-weak: #6b7575;

  --adm-color-warning: #ff8f1f;
  --adm-color-danger: #ff3141;
  --adm-color-white: #ffffff;
  --adm-color-light: #cccccc;
  --adm-border-color: #eeeeee;
  --adm-font-size-main: 13px;
  --adm-color-text: #333333;

  --font-family: 'AG Book';
  --font-family-bold: 'AG Book Bold';

  --adm-z-index-mask: 1000;
  --adm-z-index-popup: 1000;
  --adm-z-index-popover: 1030;
  --adm-z-index-floating-panel: 900;

  font-family: var(--font-family);
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

li {
  background-color: white;
}

a {
  text-decoration: none;
}

/* FORMS */

form {
  /* text-transform: uppercase; */
}

.container {
  margin-top: 2.8rem;
}

.form-container {
  color: var(--adm-color-weak);
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem; /* Añadir espacio uniforme entre elementos */
}


.form-container__logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: -50px 0;
}

.form-field {
  padding: 15px;
}

.form-field__input, select {
  border: 1px solid var(--adm-border-color);
  padding: 10px;
  width: 100%;
  background-color: white;
  border-radius: 4px;
  color: var(--adm-color-primary);
  transition: border-color 0.3s ease-in-out;
}
/* 
.form-field__input:focus, select:focus {
  outline: none;
  border-color: var(--adm-color-primary);
  box-shadow: 0 0 5px var(--adm-color-primary);
} */


.form-field__input, select {
  outline: none;
}


.form-field__error {
  color: var(--adm-color-danger);
  margin-top: 5px;
}


select {
  padding: 8px;
  border: none;
}


.footer {
  position: sticky;
  margin-top: 10%;
  left: 10px;
  bottom: 0;
  padding: 10px 0;
  /* background-color: #f5f5f5; */
}

.form-input, .custom-select {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc; /* Cambia el color del borde según lo necesites */
  border-radius: 4px; /* Agrega bordes redondeados */
  font-size: 16px;
  box-sizing: border-box;
  outline: none;
  transition: border-color 0.3s ease; /* Agrega una transición suave para los cambios de borde */
}

/* Agrega un efecto al enfocar los inputs */
.form-input:focus, .custom-select:focus {
  border-color: var(--adm-color-primary); /* Color del borde al enfocar */
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}



