.total-panel {
  position: fixed;
  bottom: 0;
  background-color: var(--adm-color-primary);
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.total-panel__total {
  padding-top: 15px;
}

.total-panel__button {
  background-color: var(--adm-color-success);
  border: 0;
  height: 50px;
}