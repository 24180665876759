.BillSuccessCard {
  margin: 1rem;
  border: 2px solid var(--adm-color-success);
  border-radius: 4px;
  box-shadow: 3px 4px 14px 0px rgba(89,89,89,0.78);
}

.ant-card-body {
  padding: 24px;
  padding-bottom: 0
}

.BillSuccessCard__body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.BillSuccessCard__body-title {
  font-size: 1.5rem;
}

.BillSuccessCard__body-item {
  margin: 0;
}

.BillSuccessCard__body-mount {
  font-weight: bolder;
  margin-top: 0.5rem;
}