.login-container {
  height: 90vh;
  position: relative;
}

.login-container-panel {
  width: 100%;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  padding: 0 20px;
}

.login-container-panel__logo {
  height: 30vh;
  padding: 0;
}

.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.login__field {
  background-color: var(--adm-color-form);
  border: none;
  padding: 15px 10px;
  margin-bottom: 20px;
  width: 100%;
}

.login__button {
  margin: 20px 0;
  padding: 10px 20px;
  width: 80%;
  background-color: var(--adm-color-success);
  color: var()
}


.link {
  display: block;
  margin-top: .5rem;
  font-size: 1rem;
}