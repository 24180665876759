@import '~antd/dist/antd.css';



.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

html, body {
  height: 0;
  width: 100vw;
   /* background-color: whitesmoke;  */

}

.adm-nav-bar-back {
  margin: 0 12px;
}