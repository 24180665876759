/* Estilo para quitar el subrayado a todos los enlaces */
a {
    text-decoration: none;
  }
  
  /* Estilos para la tarjeta */
  .card {
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 10px;
    padding: 10px;
    background-color: #fff;
  }
  
  .card-header {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .card-body {
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  .card-button {
    text-align: center;
  }
  
  .btn {
    display: inline-block;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    text-decoration: none; /* Volver a establecer la decoración de texto a ninguno */
    border-radius: 5px;
  }
  
  .btn:hover {
    background-color: #0056b3;
  }
  