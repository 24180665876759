.container {
  display: flex;
  /* flex-wrap: wrap; */
}

.col-6 {
  width: 100%;
  padding: 0 15px;
}

@media (min-width: 576px) {
  .col-6 {
    width: 50%;
  }
}

@media (min-width: 768px) {
  .col-6 {
    width: 33.33%;
  }
}

@media (min-width: 992px) {
  .col-6 {
    width: 25%;
  }
}

.label {
  width: 100%;
  text-align: center;
  background: '#AAB7B8';
  color: 'white'
}