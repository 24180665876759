/* Estilo personalizado para el contenedor del DatePicker */
.custom-datepicker-container {
    margin-bottom: 20px;
  }
  
  /* Estilo personalizado para el label "Fecha y hora del turno" */
  .custom-datepicker-label {
    font-weight: bold;
    color: #333;
  }
  
  /* Estilo personalizado para el DatePicker */
  .custom-datepicker {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    color: #333;
  }
  