.h1 {
  font-size: 1rem;
  margin-bottom: 1.5rem;
}

.logo-without-text {
  padding: 2rem 0;
  width: 33%;
  height: auto;
}

.organizations {
  width: 90%;
  margin: 1.5rem;
  font-size: 1.2rem;
}


.form-field__input {
  height: 2rem;
  color: rgba(0, 0, 0, 0.732);
}

.form-button {
  margin-top: 1.27rem;
  margin-left: 0.5rem;
  height: 2rem;
  padding: 0 1rem;
}
.organizations-manager{
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 1rem 0; 
  width: 100%;
  margin-bottom: 1rem;
}

.selected-org {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: .5rem;
  border: 1px solid #ccc;
  border-radius: .5rem;
  padding: .25rem;
}

.org-name {
  padding: .5rem 0;
  font-weight: bold;
}

.org-provider-number {
  padding: .5rem .5rem;
  color: var(--adm-color-success);
  font-size: 1rem;
  font-weight: bold;
}

.org-remove {
  cursor: pointer;
  padding: .5rem .5rem;
  color: var(--adm-color-danger);
  font-size: 1rem;
  font-weight: bold;
}

.add-org {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #ccc;
  border-radius: 0.5rem;
  padding: 1.5rem;
}