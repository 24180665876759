.turnos-container {
    width: 250px;
    /* background-color: #ffffff; */
    /* border: 1px solid #e0e0e0; */
    /* border-radius: 5px; */
    /* padding: 10px; */
    text-align: center;
    font-family: Arial, sans-serif;
    width: 100%;
    padding: 0 20px;
    margin-top: 20px;
}
  
  .turnos-title {
    color: #606262f5;
    /* font-weight: bold; */
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .divider {
    border: none;
    border-top: 1px solid #e0e0e0;
    margin: 5px 0;
  }
  
  .turno-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0;
    font-size: 14px;
    color: #6b7575;
    border-top: .2rem solid #deebda; 
  }
  
  .turno-number {
    /* font-weight: bold; */
    margin-right: 5px;
  }
  
  .turno-name {
    flex: 1;
    text-align: left;
  }
  
  .turno-time {
    color: #2AA726;
  }
  
  .bottom-border {
    height: 5px;
    background-color: #2AA726;
    margin-top: 15px;
  }
  