
.home-footer{
    /* background-color: #f5f5f5; */
    margin-top: 10px;
    padding: 20px;
    text-align: center;
    font-size: 14px;
    color: #666;
    display: flex;
    justify-content: space-around;
    align-items: center;
}